import React, { useEffect, useState } from "react";
import { useSelector} from "react-redux";
import VIcon from "components/custom/Icon";
import { themeIcons } from "config/theme";
import { stringIsNullOrEmpty } from '../../util/Util'
import "../../assets/css/bannerapk.css"
import { useLocation } from 'react-router-dom';
import {
  WebUrl,
} from "../../util/Constant";

const BannerAPK = () => {
  var { heartBeat } = useSelector((state) => state.authState);
  
  const [shouldShowBannerAPK, setShouldShowBannerAPK] = useState(false);
  const [clickCloseBannerAPK, setClickCloseBannerAPK] = useState(false);
  const [bannerAPKImg,setBannerAPKImg]=useState("");
  const [bannerAPKUrl,setBannerAPKUrl]=useState("");

  const [iconColor, setIconColor] = useState('#FFF');
  const bannerAPK=useSelector((state)=>state.appState.bannerAPK);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();



	useEffect(() => {
    
		init();

    const parentElement = document.querySelector('.page-container');
    
    if(parentElement){
    const backgroundColor = window.getComputedStyle(parentElement).backgroundColor; 
    const isDarkTheme = isDarkBackground(backgroundColor);
    setIconColor(isDarkTheme ? '#FFF' : '#383838');
    }
    
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

	}, []);

  useEffect(()=>{

    let bannerAPKIcon=bannerAPK;
   
    if(bannerAPKIcon){
     if (!stringIsNullOrEmpty(bannerAPKIcon?.desktopImage)){
     setBannerAPKImg(bannerAPKIcon.desktopImage);
     }

     if(isMobile && !stringIsNullOrEmpty(bannerAPKIcon?.mobileImage)){
      setBannerAPKImg(bannerAPKIcon.mobileImage);
      }
  }

  },[isMobile])

  // useEffect(() => {
  //   init()
  // }, [heartBeat]);

  async function init() {

    let bannerAPKIcon=bannerAPK;
    
    if(bannerAPKIcon){
    if(!stringIsNullOrEmpty(bannerAPKIcon?.desktopImage) && isValidApkUrl(bannerAPKIcon?.url)){

      setBannerAPKUrl(bannerAPKIcon.url);
      
      if(!clickCloseBannerAPK){
        setShouldShowBannerAPK(true);
      }

    }else{
      setShouldShowBannerAPK(false);
    } 
    }else{
      setShouldShowBannerAPK(false);
    }
  }

  function isValidApkUrl(url) {
    try {

      if(stringIsNullOrEmpty(url))return false;

      const parsedUrl = new URL(url);
      return parsedUrl.href.toLowerCase().endsWith(".apk");
    } catch (e) {
      return false;
    }
  }

  const handleDownloadAPK = () => {
    const fileName = bannerAPKUrl.substring(bannerAPKUrl.lastIndexOf("/") + 1);
    const link = document.createElement("a");
    link.href = bannerAPKUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [imgLoad, setImgLoad] = useState(false);

  const BannerAPKIcon = (props) => (
    <img className="banner-apk-icon" alt="banner-apk-icon" src={bannerAPKImg} onLoad={() => setImgLoad(true)}/>
  );

  const isDarkBackground = (bgColor) => {
    const rgb = bgColor.match(/\d+/g);
    if (rgb) {
      const r = parseInt(rgb[0], 10);
      const g = parseInt(rgb[1], 10);
      const b = parseInt(rgb[2], 10);
      return (r * 0.299 + g * 0.587 + b * 0.114) < 128;
    }
    return false;
  };

  const CloseBtn = (props)=> (
    <div
          className="spin-wheel-close" style={{ color: iconColor }}
          onClick={(e) => {
              e.stopPropagation();
              setShouldShowBannerAPK(false);
              setClickCloseBannerAPK(true);      
          }}
    >
      <VIcon icon={themeIcons.close} className="vicon" />
    </div>
  );

	return (
    <>
      {shouldShowBannerAPK && location.pathname !== "/"+ WebUrl.getCurrentLocale() +"/livechat" && (
        <div id="banner-apk">
          <div 
          style={{ position: "relative", display: "inline-block",padding:"10px"}}
          onClick={handleDownloadAPK}>
             {imgLoad && <CloseBtn />}
            < BannerAPKIcon className="banner-apk-icon" />
          </div>
        </div>
      )}
    </>
	);
};

export default BannerAPK;
